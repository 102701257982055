import { AlertMessageType, ERROR_CODE, SUCCESS_CODE } from 'constants/common';
import { useCallback, useState } from 'react';
import axiosService from 'requests/axios';
import { ImageOptions } from 'utils/image.utils';

interface UseUpdateAppConfigurationProps {
  maxInvitationAllowed: number;
  maxDurationAllowed: number;
  minAppVersion: string;
  validUrls: number[];
  calendarImage: ImageOptions | null;
}

export const useUpdateAppConfiguration = (body: UseUpdateAppConfigurationProps) => {
    const [updateAppConfigurationMessage, setUpdateAppConfigurationMessage] = useState<AlertMessageType | null>(null);
    const [isUpdateAppConfigurationLoading, setIsUpdateAppConfigurationLoading] = useState<boolean>(false);

    const updateAppConfiguration = useCallback(async () => {
        setIsUpdateAppConfigurationLoading(true);

        const { calendarImage, ...restBody } = body;

        try {
            await axiosService.patch('app-configuration', restBody);

            if (calendarImage?.blob) {
                const formData = new FormData();
                formData.append('file', calendarImage.blob);

                await axiosService.put('app-configuration/calendar-image', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            } else if (calendarImage === null) {
                await axiosService.delete('app-configuration/calendar-image');
            }

            setUpdateAppConfigurationMessage({
                message: 'App configuration successfully updated!',
                code: SUCCESS_CODE
            });
        } catch (error) {
            setUpdateAppConfigurationMessage({
                message: 'Something went wrong!',
                code: ERROR_CODE
            });
        } finally {
            setIsUpdateAppConfigurationLoading(false);
        }
    }, [body]);

    return {
        updateAppConfiguration,
        isUpdateAppConfigurationLoading,
        updateAppConfigurationMessage,
    };
};
