import { Box, Button, OutlinedInput, Typography } from '@mui/material';
import { CATEGORIES_PAGE_MODE, ERROR_CODE, SUCCESS_CODE } from 'constants/common';
import React, { FormEvent, useState } from 'react';
import ImageUploader from 'components/ImageUploader';
import axiosService from 'requests/axios';
import { CategoryResponse } from 'interfaces/responses/Category';
import { uploadCroppedImage } from 'utils/image.utils';
import PageWrapper from 'components/PageWrapper';
import PageHeader from 'components/PageHeader';

interface CategoryFormProps {
    mode: string,
    setCategoriesMode: any,
    setUserMessage: any,
    category?: CategoryResponse | null,
}

const CategoryForm: React.FC<CategoryFormProps> = ({
    mode, 
    setCategoriesMode,
    setUserMessage,
    category
}) => {
    const [categoryIcon, setCategoryIcon] = useState(category ? { src: category.icon, blob: null } : null);
    const [coverImage, setCoverImage] = useState(category ? { src: category.coverImage, blob: null } : null);
    const [name, setName] = useState(category ? category.name : '');
    const [loading, setLoading] = useState(false);

    const isSubmitDisabled = !name || !categoryIcon || !coverImage || loading;

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setLoading(true);

        try {
            const data: any = {};

            if (name !== (category?.name || '')) {
                data.name = name;
            }

            if (categoryIcon?.blob) {
                const iconLocation = await uploadCroppedImage(categoryIcon.blob);
                setCategoryIcon(prevIcon => prevIcon ? { ...prevIcon, src: iconLocation } : null);
                data.icon = iconLocation;
            }

            if (coverImage?.blob) {
                const coverLocation = await uploadCroppedImage(coverImage.blob);
                setCoverImage(prevCover => prevCover ? { ...prevCover, src: coverLocation } : null);
                data.coverImage = coverLocation;
            }

            if (mode === CATEGORIES_PAGE_MODE.ADD) {
                await axiosService.post('/category/', data);
            } else if (mode === CATEGORIES_PAGE_MODE.EDIT) {
                await axiosService.patch(`/category/${category?.id}/`, data);
            }

            setUserMessage({
                message: mode === CATEGORIES_PAGE_MODE.ADD ? 'Category added successfully!' : `Category ${name} successfully updated!`,
                code: SUCCESS_CODE
            });
            setCategoriesMode(CATEGORIES_PAGE_MODE.GET);
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || '';
            const message = errorMessage.includes('Duplicate entry') ?
                'Category name must be unique. Please choose a different name.' :
                'Something went wrong!';

            setUserMessage({
                message,
                code: ERROR_CODE
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box display={'flex'} flexDirection={'column'} gap={'23px'}>
            <PageHeader title={mode === CATEGORIES_PAGE_MODE.ADD ? 'Add New Category' : 'Edit Category'} onClick={() => setCategoriesMode(CATEGORIES_PAGE_MODE.GET)} />
            <form onSubmit={handleSubmit} autoComplete='no'>
                <PageWrapper>
                    <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
                        <Typography variant='h6'>Name</Typography>
                        <OutlinedInput placeholder='Write down some category name' value={name} onChange={(e) => setName(e.target.value)}></OutlinedInput>
                    </Box>

                    <Box display={'flex'} flexDirection={'row'} gap={'24px'} width={'100%'}>
                        <ImageUploader imageWidth={120} imageHeight={120} submitedImage={categoryIcon} setSubmitedImage={setCategoryIcon} 
                            setUserMessage={setUserMessage} title='Image Icon' description='PNG or JPG (100x100px, max. 2MB)'/>

                        <ImageUploader imageWidth={120} imageHeight={120} submitedImage={coverImage} 
                            setSubmitedImage={setCoverImage} setUserMessage={setUserMessage} title='Cover Image' description='PNG or JPG (250x250px, max. 2MB)'/>
                    </Box>

                    <Box display={'flex'} flexDirection={'row'} gap='24px' justifyContent={'flex-end'}>
                        <Button size='medium' color='primary' variant='text' onClick={() => setCategoriesMode(CATEGORIES_PAGE_MODE.GET)}>Cancel</Button>
                        <Button size='medium' color='secondary' variant='contained' type="submit" disabled={isSubmitDisabled}>{mode === CATEGORIES_PAGE_MODE.ADD ? 'Add Category' : 'Save Changes'}</Button>
                    </Box>
                </PageWrapper>
            </form>
        </Box>
    );
};

export default CategoryForm;
