import React, { ChangeEvent, useRef, useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import Slider from '@mui/material/Slider';
import { ERROR_CODE } from '../../constants/common';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import getCroppedImg from 'utils/image.utils';
import { Box, Button, Dialog, Typography } from '@mui/material';

import './index.css';

interface CropImagePopupProps {
    image: string;
    setUserMessage: any;
    setSubmittedImage: any;
    open: boolean;
    close: () => void;
    cropSize?: { width: number, height: number }
}

interface ImageProps {
    imageSrc: string;
    crop: { x: number; y: number };
    zoom: number;
    aspect: number;
}

export const CropImagePopup: React.FC<CropImagePopupProps> = ({
    image,
    setUserMessage,
    open,
    close,
    setSubmittedImage,
    cropSize
}) => {
    const [imageProps, setImageProps] = useState<ImageProps>({
        imageSrc: image,
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 1,
    });
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
    const imageInputRef = useRef<HTMLInputElement>(null);

    const onCropChange = (crop: { x: number; y: number }) => {
        setImageProps({ ...imageProps, crop });
    };

    const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const onZoomChange = (zoom: number) => {
        setImageProps({ ...imageProps, zoom });
    };

    const saveImage = async () => {
        try {
            if (!croppedAreaPixels) return;
            const croppedImage = await getCroppedImg(
                imageProps.imageSrc,
                croppedAreaPixels,
            );
            setSubmittedImage(croppedImage);
            close();
        } catch (e) {
            console.error(e);
        }
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file && file.type.startsWith('image/')) {
            setImageProps({ ...imageProps, imageSrc: URL.createObjectURL(file) });
        } else {
            setUserMessage({
                message: 'File format isn\'t accepted',
                code: ERROR_CODE
            });
        }
    };

    return (
        <Dialog
            open={open}
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box p={'40px 88px'} display={'flex'} flexDirection={'column'} gap='24px' alignItems={'center'}>
                <Typography variant='h5'>Upload Image</Typography>
                <Box className='cropper-container'>
                    <Cropper
                        image={imageProps.imageSrc}
                        crop={imageProps.crop}
                        zoom={imageProps.zoom}
                        aspect={imageProps.aspect}
                        cropSize={cropSize ? cropSize : undefined}
                        cropShape="rect"
                        showGrid={false}
                        onCropChange={onCropChange}
                        onCropComplete={onCropComplete}
                        onZoomChange={onZoomChange}
                    />
                </Box>
                <Box className="controls">
                    <RemoveCircleIcon fontSize="small" />
                    <Slider
                        value={imageProps.zoom}
                        min={1}
                        max={2.5}
                        step={0.01}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => onZoomChange(zoom as number)}
                    />
                    <AddCircleIcon fontSize="small" />
                </Box>

                <Box display={'flex'} flexDirection={'row'} gap='24px' alignItems={'center'}>
                    <Button variant={'outlined'} color='primary' size='medium' onClick={() => imageInputRef?.current?.click()}>
                        <input
                            type="file"
                            accept="image/*"
                            ref={imageInputRef}
                            style={{display: 'none'}}
                            onChange={handleFileChange}
                        />
                        Upload
                    </Button>
                    <Button variant={'contained'} color='secondary' size='medium' onClick={saveImage}>Save</Button>
                </Box>
            </Box>
        </Dialog>
    );
};
